@import '../../assets/styles/colors';
.modal {
  [role='dialog'] {
    width: 80%;
    margin: 0 auto;
    max-width: 520px;
    padding: 20px;
    //padding-top: 40px;
    text-align: center;
    height: 80%;
    border: 1px solid $shadow;
    background-color: $primary;
    //box-shadow: 0px 4px 4px 5px $shadow;
    h2 {
      font-weight: bold;
    }
    .dialog-close{
      z-index: 2;
    }
    .dialog-content{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: solid 1px $hover;
    }
    b {
      font-weight: bold;
    }
  }
}
