@import '../../assets/styles/colors';

.home {

.welcome-wrapper{
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  h1{
    color: $hover;
  }
}
.button-wrapper{
  button, a{
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
}
}