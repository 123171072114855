
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Playfair+Display:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap');
* {
  font-family: 'Playfair Display', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.playfair{
  font-family: 'Playfair Display', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.pacifico{
  font-family: 'Pacifico', cursive;
  font-size: 16px;
  font-weight: 400;
}
.nunito{
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

h1{
  font-size: 1.5rem;
  font-weight: 800;
}
h2{
  font-size: 1.2rem;
}
h3{
  font-size: 1rem;
}