// $primary: #FFFCFB;
// $text: #4C5049;
// $secondary: #A4C18B;
// $hover: #BECCB2;
//$shadow:rgba(0, 0, 0, 0.25);
//$disabled: #f3f3f3;
//$disabled-text: #c2c2c2;

// $primary: #FFF;
// $text: #776c6e;
// $secondary: #ffe7ea;
// $hover: #d0bcbf;
// $shadow: rgba(189,161,87,0.5);
// $disabled: #f3f3f3;
// $disabled-text: #c2c2c2;

$primary: #fff;
$text: #ADA198;
//$text: #3D332A;
$secondary: #DED3CB;
$hover: #3D332A;
$shadow: #DED3CB;
$disabled: #f3f3f3;
$disabled-text: #c2c2c2;