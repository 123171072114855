@import '../../assets/styles/colors';

form {
  max-width: 350px;
  margin: 0 auto;
  *{
    box-sizing:border-box;
  }
  .form-field {
    width: 100%;
    text-align: left;
    //margin-bottom: 20px;

    label {
      display: block;
      width: 100%;

      h2 {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        color: $text;
      }

      input,
      textarea {
        display: block;
      }
    }

    input[type="text"],
    input[type="password"],
    input[type="file"],
    textarea {
      border: 1px solid $text;
      border-radius: 14px;
      min-height: 46px;
      width: 100%;
      max-width: 100%;
      padding: 5px 10px;
      background-color: $primary;
      margin: 10px 0;
      &:focus, &:active {
        box-shadow: none;
        outline: none;
      }
    }
    input[type="file"]{
      padding: 0;
      position: absolute;
      top: 50%;
      margin-top: -23px;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      border: 0;
      &:before{
        content: "";
        background-image: url('../images/upload.svg');
        background-repeat: no-repeat;
        background-size: contain;
        color: $text;
        display: block;
        position: absolute;
        top: 5px;
        left: 8px;
        width: 35px;
        height: 35px;
        z-index: 2;
      }
    }
    input::file-selector-button {
      font-weight: bold;
      color: transparent;
      padding: 0.5em;
      border: 2px solid transparent;
     // border-top-left-radius: 12px;
     // border-bottom-left-radius: 12px;
      background-color: transparent;
      height: 46px;
      position: relative;
      width: 50px;
    }
  }

}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease-in-out;
  z-index: 11;
  span{
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 50%;
    margin-left: -20px;
    color: $primary;
  }
}