.alert{
  & > div {
  }
strong{
  font-weight: bold;
}
ul{
  padding: 0;
  margin: 0;
  li{
    padding: 0;
    text-align: left;
  }
}
}