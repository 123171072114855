@import '../../assets/styles/colors';

.photos {
  z-index: 3;
  position: relative;

  .photos-content{
    height: 100vh;
    .gallery-tabs{
      position: fixed;
      top: 52px;
      left: 0;
      height: 50px;
      width: 100%;
      z-index: 10;
      background-color: $primary;
      & > div{
        padding: 0;
      }
    }
    .gallery-tab{
      color: $text;
    }
    .css-1aquho2-MuiTabs-indicator{
      background-color: $text;
    }
  }
}