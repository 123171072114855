@import './assets/styles/_colors';
@import './assets/styles/_typography';

.app {
  text-align: center;
  background-color: $primary;
  color: $text;
  padding: 0!important;
  margin: 0;

  @media screen and (max-height: 550px){
    display: block;
  }

  .App{
    padding: 0;
    z-index: 1;
    margin: 0 auto;
    position: relative;
  }
  .site {
      padding-bottom: 60px;
  }
  .login{
    padding: 20px;
  }

  // &.decorative:before{
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 300px;
  //   background-image: url('./assets/images/top-pink.png');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: left top;
  //   top: 0;
  //   left: 0;
  //   z-index: 0;
  //   display: block;
  // }
  // &.decorative:after{
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 300px;
  //   background-image: url('./assets/images/bottom-pink.png');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: right bottom;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 0;
  //   display: block;
  // }

  @media screen and (max-height: 550px){
    display: block;
    &.decorative:before{
    position: fixed;
  }
  &.decorative:after{
    position: fixed;
  }
    
  }
}


