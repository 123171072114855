@import '../../assets/styles/colors';
@import '../../assets/styles/typography';
.photo-list{
  width: 100%;
  .post{
    display:block;
    border: 1px solid $text;
    &:first-of-type{
      padding-top: 50px;
    }
    &:last-of-type{
      padding-bottom: 60px;
    }
    div{
      display: block;
      margin: 0;
      font-size: 14px;
      line-height: 14px;
    }
  }
  .photo-wrapper {
    background-color: $text;
    position: relative;
    transition: height 0.3s ease;

    >span {
      width: 100%;
    }

    .sizing {
      position: absolute;
      display: none;
      left: 30px;
      top: 50px;
      z-index: 9999999;
      
    }

    &.collapsed{
      overflow: hidden;
      height: 280px !important;
      .sizing{
        display: block;
        .collapse{
          display: none;
        }
      }
    }
    &.expanded{
      max-height: none;
      .sizing{
        display: block;
        .expand{
          display: none;
        }
      }
    }
    
    img {
        width: 100%;
      object-fit: cover;
  }
}
  .wish{
    text-align: left;
    padding: 10px 20px;
    
    h2{
      font-weight: bold;
      color: $text;
      margin: 0;
      margin-bottom: 10px;
      line-height: 1.2rem;
    }
    p{
      margin: 0;
    }
        p.date {
      font-size: 12px;
      color: $hover;
      margin-top: 10px;
      font-family: 'Nunito', sans-serif;
      font-size: 10px;
      line-height: 10px;
    }

  }
}