@import '../../assets/styles/colors';

button, a {

&.btn{
  border-radius: 14px;
  text-align: center;
  box-shadow: none!important;
  font-weight: 800;
  font-family: 'Playfair Display', sans-serif;
  min-height: 46px;
  display: block;
  width: 100%;
  text-transform: uppercase;
  &:disabled{
    border: 1px solid $disabled-text;
    color: $disabled-text;
    background-color: $disabled;
  }
}
&.btn-inline{
  display: inline-block;
  width: auto;
}

&.btn-transparent{
  background-color: transparent;
  border: 1px solid $text;
  color: $text;
  &:hover{
    background-color: transparent;
    border: 1px solid $hover;
    color: $hover;
  }
}
&.btn-white{
  background-color: transparent;
  border: 1px solid $text;
  color: $text;
  &:hover{
    background-color: transparent;
    border: 1px solid $hover;
    color: $hover;
  }
}

&.btn-dark{
  background-color: $text;
  border: 1px solid $text;
  color: $primary;
  &:hover{
    background-color: $hover;
    border: 1px solid $hover;
    color: $primary;
  }
  
}

&.btn-icon, &.btn-icon:hover{
  color: $hover;
  border:none;
}

&.btn-large{
  padding: 10px;
  font-weight: 900;
}
&.btn-small{
  padding: 10px;
  font-weight: 900;
  font-size: 12px;
  height: auto;
  min-height: 1px;
}

&.btn-tiny{
  padding: 10px;
  font-weight: 900;
  font-size: 12px;
  min-height: 0;
}

&.btn-link{
  background: none;
  color: $hover;
  font-size: 12px;
  border: none;
  font-weight: bold;
  text-decoration: underline;
  padding: 0;
}

&.primary{
  @extend .btn;
  @extend .btn-white;
}

&.secondary{
   @extend .btn;
   @extend .btn-dark;
}
&.secondary--filled{
   @extend .btn;
   @extend .btn-white;
}

&.functional{
  @extend .btn;
   @extend .btn-icon;
   @extend .btn-tiny;
   padding: 0;
    width: 40px;
    height: 40px;
    svg{
      width: 40px;
      height: 40px;
    }
}

}