@import '../../assets/styles/colors';
.buttons-footer {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-top: 1px solid $text;
  margin: 0 auto;

  .buttons-wrapper {
    padding: 10px 10px;
    .btn-small {
      display: inline-block;
      width: auto;
      text-decoration: none;
      vertical-align: middle;
      min-width: 120px;
      &:first-of-type {
        margin-right: 2%;
      }
      &.icon{
        text-align: center;
        padding: 5px;
        font-size: 0.5rem;
        font-weight: bold;
        min-width: 1px;
        svg{
          font-size: 24px;
          line-height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 320px){
    .buttons-wrapper .btn-small:not(.icon){
      min-width: 5px;
      &:first-of-type {
        margin-right: 1%;
      }
    }
  }
}
.couple-wrapper {
  width: 100%;
  .container-wrapper{
        display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: stretch;
    flex-direction: row;
    align-items: center;
  }
  span{
      display: inline-block;
      padding: 10px;
      vertical-align: top;
      margin-top: 5px;
      font-weight: bold;
      color: $secondary;
    }
  &.big {
    position: relative;
    margin-top: 20px;
    .bird{
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: calc(50% - 90px);
        height: 120px;
        background-position: center;
    }
    .bird-left{
        background-image: url('../../assets/images/bird-left1.png');
        margin-bottom: 30px;
        margin-right: 10px;
      }
    .bird-right{
        background-image: url('../../assets/images/bird-right1.png');
        margin-left: 10px;
        margin-top: 30px;
    }
    .image-wrapper{
      border-radius: 100%;
      //box-shadow: 0px 0px 10px 0px $hover;
      border: 1px solid $secondary;
      width: 150px;
      height: 150px;
      overflow: hidden;
      display: inline-block;
      &:before{ 
        display: block;
        content: '';
        position: absolute;
        width: 155px;
        height: 155px;
        border: solid 1px $hover;
        margin: 10px;
        background-color: transparent;
        border-radius: 100px;
        top: 0px;
        margin-left: 5px;
        margin-top: 5px;
        z-index: 2;
      }
      &:after{ 
        display: block;
        content: '';
        position: absolute;
        width: 155px;
        height: 155px;
        border: solid 1px $secondary;
        margin: 10px;
        background-color: transparent;
        border-radius: 100px;
        top: 0px;
        margin-left: -10px;
        margin-top: -10px;
        z-index: 2;
      }
      img {
        height: 100%;
      }
    }
  }
  &.small {
    //display:none;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid $text;
    z-index: 10;padding-bottom: 2px;
    vertical-align: middle;
    .bird{
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 70px;
        height: 45px;
        background-position: center;
    }
    .bird-left{
        background-image: url('../../assets/images/bird-left1.png');
        //margin-bottom: 30px;
        //margin-right: 10px;
      }
    .bird-right{
        background-image: url('../../assets/images/bird-right1.png');
        //margin-left: 10px;
        //margin-top: 30px;
    }
    .image-wrapper {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      margin-top: 5px;
      overflow: hidden;
      border: 1px solid $secondary;
      //box-shadow: 0px 0px 10px 0px $shadow;
      display: inline-block;
      
      img {
        height: 100%;
      }
    }
  }
}
