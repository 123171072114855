@import "../../assets/styles/colors";
.upload {
  form {
    margin-top: 70px;
  }
  form .form-field label {
    text-align: left;
    h2 {
      display: inline-block;
      //color: $hover;
    }
  }
  form .form-field .Mui-active label h2{
    color: $hover;
  }

  .crop-container2 {
    position: relative;
    // margin: 30px auto;
    // width: 500px;
    height: 296px;
  }
  .cropper-wrapper{
    position: relative;
    height: 300px;
    width: 300px;
    max-width: 100%;
    border: 2px dashed $text;

  }
  .cropper-hyperlink{
        margin: 10px 0;
    display: block;
  }
  svg.MuiSvgIcon-root.MuiStepIcon-root{
    color: $secondary!important;
  }
  svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed{
    color: $text!important;
  }
  svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-active{
    color: $hover!important;
  }

  .cheers{
    width: 100%;
    height: 150px;
    background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
         background-image: url('../../assets/images/cheers.png');
  }
  .thanks-msg{
    p{
      color: $hover;
    }
  }
}
